@use '@kronoss/styles/globals' as *;
.NotFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include lg {
    padding-top: 48px + 80px;
  }
  .Title {
    font-size: 300px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 48px;

    @include lg {
      font-size: 146.104px;
      margin-bottom: 32px;
    }
  }
  .Subtitle {
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
    @include lg {
      font-size: 16.638px;
      margin-bottom: 10px;
    }
  }
  .Description {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 48px;
    @include lg {
      font-size: 13.31px;
      margin-bottom: 32px;
    }
  }
  .Button {
    margin-bottom: 48px;
  }
}
